import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Icon, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiRoundStar } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Shenzhen Starnoon Technology Co., LTD Sports Bar
			</title>
			<meta name={"description"} content={"Welcome to Shenzhen Starnoon Technology Co., LTD, a sports bar with a twist. Here, we blend the love of sports with a laid-back atmosphere, creating the perfect spot for fans and friends alike."} />
			<meta property={"og:title"} content={"Home | Shenzhen Starnoon Technology Co., LTD Sports Bar"} />
			<meta property={"og:description"} content={"Welcome to Shenzhen Starnoon Technology Co., LTD, a sports bar with a twist. Here, we blend the love of sports with a laid-back atmosphere, creating the perfect spot for fans and friends alike."} />
			<meta property={"og:image"} content={"https://axiolblade.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://axiolblade.live/img/723593.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://axiolblade.live/img/723593.png"} />
			<meta name={"msapplication-TileImage"} content={"https://axiolblade.live/img/723593.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://axiolblade.live/img/1.jpg) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline1"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Shenzhen Starnoon Technology Co., LTD Sports Bar
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
					max-width="750px"
				>
					Welcome to Shenzhen Starnoon Technology Co., LTD, a sports bar with a twist. 
			Here, we blend the love of sports with a laid-back atmosphere, creating the perfect spot for fans and friends alike.{" "}
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Why Shenzhen Starnoon Technology Co., LTD Scores Big
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							After a long day or week, Shenzhen Starnoon Technology Co., LTD is your go-to destination to relax, talk sports, enjoy fun activities, and unwind in good company.
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Relaxed Sports Vibes
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Enjoy the excitement of sports in a chill environment, perfect for unwinding.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									The Social Field
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								A great place to meet new people or have a laugh with our amiable bar staff, who are always ready to chat about the latest game or life in general.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--darkL2">
				A Sneak Peek at Our Game Day Specials
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 300 25px/1.5 --fontFamily-serifGaramond"
				color="--dark"
				width="700px"
				align-self="center"
				md-align-self="auto"
				max-width="100%"
			>
				At Shenzhen Starnoon Technology Co., LTD Sports Bar, our menu is a league of its own, offering a variety of flavors that cater to every fan.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://axiolblade.live/img/2.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Icon
							category="gi"
							icon={GiRoundStar}
							size="64px"
							background="#ffec00"
							padding="15px 15px 15px 15px"
							border-radius="50%"
							margin="-40px 0px 20px 0px"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
								Pre-Game Warm-Ups
							</Text>
							<Text margin="0px 0px 0px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Sideline Nachos: A towering plate of crispy tortilla chips, smothered in melted cheese, jalapeños, and a hearty scoop of guacamole.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://axiolblade.live/img/3.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Icon
							category="gi"
							icon={GiRoundStar}
							size="64px"
							background="#ffec00"
							padding="15px 15px 15px 15px"
							border-radius="50%"
							margin="-40px 0px 20px 0px"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
								Main Event Eats
							</Text>
							<Text margin="0px 0px 0px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								The Linebacker Burger: A hefty beef patty, layered with cheese, bacon, and our signature Locker Room sauce, all sandwiched in a toasted brioche bun.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://axiolblade.live/img/4.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Icon
							category="gi"
							icon={GiRoundStar}
							size="64px"
							background="#ffec00"
							padding="15px 15px 15px 15px"
							border-radius="50%"
							margin="-40px 0px 20px 0px"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
								Halftime Refreshments
							</Text>
							<Text margin="0px 0px 0px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Draft All-Stars: A rotating selection of craft beers, perfect for toasting to your team's success.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 80px 0" md-padding="80px 0 80px 0" sm-padding="50px 0 50px 0" quarkly-title="CTA/Lead/Form-3">
			<Box
				min-width="100px"
				min-height="100px"
				padding="80px 80px 80px 80px"
				border-radius="40px"
				md-padding="50px 50px 50px 50px"
				sm-padding="50px 30px 50px 30px"
				background="--color-dark"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--light"
					text-align="center"
					font="normal 700 38px/1.2 --fontFamily-sans"
					lg-margin="0px 0 20px 0"
					sm-font="normal 700 36px/1.2 --fontFamily-sans"
					sm-text-align="left"
				>
					Step into Shenzhen Starnoon Technology Co., LTD for an experience where the game and the grub are equally thrilling!
				</Text>
				<Text
					margin="0px 200px 50px 200px"
					text-align="center"
					font="--lead"
					color="rgba(255, 255, 255, 0.8)"
					lg-margin="0px 0 50px 0"
					sm-text-align="left"
				>
					For the full lineup or to discuss specific dietary needs, feel free to reach out to us. We aim to provide a winning combination of flavor and fun, suitable for every fan.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					justify-content="center"
					sm-flex-direction="column"
					sm-align-items="center"
				>
					<Button
						background="rgba(247, 251, 255, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						font="normal 400 17px/1.5 --fontFamily-sans"
					>
						Contact Us &gt;&gt;
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Foopter quarkly-title="Footer" />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});